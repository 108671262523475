import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";

function CustomModal({ show, handleClose }) {
  const { t } = useTranslation();
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          {t(
            "To enjoy a better experience we recommend that you install the Veyve APP"
          )}
          ....!
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Ignore
          </Button>
          <Button
            variant="primary"
            style={{
              background: "#35C89B",
            }}
            onClick={() => {
              handleClose();

              const isAndroid = /Android/i.test(navigator.userAgent);
              const isiOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

              const androidUrl =
                "https://play.google.com/store/apps/details?id=com.veyve&hl=en";
              const iosUrl = "https://apps.apple.com/app/veyve/id6450931489";

              if (isAndroid) {
                window.open(androidUrl, "_blank"); // Open Android URL in new tab
              } else if (isiOS) {
                window.open(iosUrl, "_blank"); // Open iOS URL in new tab
              } else {
                console.log("Platform not detected or unsupported");
              }
            }}
          >
            Download
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default CustomModal;
