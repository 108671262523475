import React, { useEffect, useState } from "react";
import ImagePlaceholder from "../../Assets/ImagePlaceholder.png";
import locationMarker from "../../Assets/locationBlackIcon.png";
import starIcon from "../../Assets/starIcon.png";
import starIcon2 from "../../Assets/starIcon2.png";
import styles from "./style.module.css";
import { useNavigate } from "react-router-dom";
import API from "../../Config/API";
import { formatTitle } from "../../logics/services";

const EventsCard = ({ value }) => {
  const [currentUser, setCurrentUser] = useState("");
  const [isFollowed, setIsFollowed] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const user = localStorage.getItem("UserDetails");
    const parsedUser = JSON.parse(user);
    setCurrentUser(parsedUser);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      checkIsFollowed();
    }, 3000);
  }, [currentUser]);

  const details = () => {

    navigate(`/event-details/${formatTitle(value?.title)}/${value?.id}`, { state: value });
  };

  const followEvent = async () => {
    if (currentUser) {
      try {
        const res = await API.post("/follow", {
          type: "event",
          userId: currentUser?.id,
          valueId: value?.id,
        });
        setIsFollowed(!isFollowed);
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  const checkIsFollowed = async () => {
    if (currentUser) {
      try {
        const res = await API.post("/isFollow", {
          type: "events",
          userId: currentUser?.id,
          valueId: value?.id,
        });
        if (res?.data?.data === "yes") {
          setIsFollowed(true);
        } else if (res?.data?.data === "yes") {
          setIsFollowed(false);
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  return (
    <div className={styles.cardBox}>
      {currentUser && (
        <section className={styles.fvrtIconBox} onClick={followEvent}>
          {isFollowed ? (
            <img
              src={starIcon2}
              width={"30px"}
              height={"30px"}
              alt="starIcon"
            />
          ) : (
            <img
              src={starIcon}
              width={"30px"}
              height={"30px"}
              alt="starIconColred"
            />
          )}
        </section>
      )}
      <section className={styles.cardImageBox} onClick={details}>
        {value?.imagesArray?.length > 0 && value?.imagesArray[0]?.image ? (
          <img
            src={`https://veyveback.com/${value?.imagesArray[0]?.image}`}
            alt={"eventImg"}
            width="100%"
            height={"200px"}
          />
        ) : (
          <img
            src={ImagePlaceholder}
            alt={"eventImg"}
            width="100%"
            height={"200px"}
          />
        )}
        <div className={styles.imgBoxText}>
          <p>
            {value?.publicationDate} -{value?.publicationTime}
          </p>
          <p>Luness, 21:00h</p>
        </div>
      </section>
      <section className={styles.cardContentParent} onClick={details}>
        <h6>{value?.title}</h6>
        <section className={styles.cardContent}>
          <div className={styles.child1}>
            <p>{value?.address}</p>
            {value?.distance && (
              <div className={styles.locationBOx}>
                <section className={`d-flex ${styles.imgChild1}`}>
                  <img
                    src={locationMarker}
                    alt="locationMarker"
                    width="30px"
                    height="20px"
                  />
                </section>
                <section className={`d-flex ${styles.imgChild2}`}>
                  <span>{value?.distance} KM </span>
                </section>
              </div>
            )}
          </div>
        </section>
      </section>
    </div>
  );
};

export default EventsCard;
