import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import badIcon from "../../Assets/badIcon.png";
import locationMarker from "../../Assets/locationBlackIcon.png";
import spoonIcon from "../../Assets/spoonIcon.png";
import star from "../../Assets/starIcon.png";
import locationBlack from "../../Assets/locationBlackIcon.png";
import { useNavigate } from "react-router-dom";
import API from "../../Config/API";
import starIcon from "../../Assets/starIcon.png";
import starIcon2 from "../../Assets/starIcon2.png";
import ImagePlaceholder from "../../Assets/ImagePlaceholder.png";
import { formatTitle } from "../../logics/services";

const ServicesCard = ({ value }) => {
  const [currentUser, setCurrentUser] = useState("");
  const [isFollowed, setIsFollowed] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const user = localStorage.getItem("UserDetails");
    const parsedUser = JSON.parse(user);
    setCurrentUser(parsedUser);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      checkIsFollowed();
    }, 3000);
  }, [currentUser]);

  const details = () => {
    const title =
      (value?.imagesArray &&
        value?.imagesArray?.length > 0 &&
        value?.imagesArray[0]?.text) ||
      value?.title;

    navigate(`/service-details/${formatTitle(title)}/${value?.id}`, {
      state: value,
    });
  };

  // Follow Service
  const followService = async () => {
    if (currentUser) {
      try {
        const res = await API.post("/follow", {
          type: "service",
          userId: currentUser?.id,
          valueId: value?.id,
        });
        setIsFollowed(!isFollowed);
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  const checkIsFollowed = async () => {
    if (currentUser) {
      try {
        const res = await API.post("/isFollow", {
          type: "services",
          userId: currentUser?.id,
          valueId: value?.id,
        });
        if (res?.data?.data === "yes") {
          setIsFollowed(true);
        } else if (res?.data?.data === "yes") {
          setIsFollowed(false);
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  return (
    <div className={styles.cardBox}>
      {currentUser && (
        <section className={styles.fvrtIconBox} onClick={followService}>
          {isFollowed ? (
            <img
              src={starIcon2}
              width={"30px"}
              height={"30px"}
              alt="starIcon"
            />
          ) : (
            <img
              src={starIcon}
              width={"30px"}
              height={"30px"}
              alt="starIconColred"
            />
          )}
        </section>
      )}
      <section className={styles.cardImageBox} onClick={details}>
        {value?.imagesArray?.length > 0 && value?.imagesArray[0]?.image ? (
          <img
            src={`https://veyveback.com/${value?.imagesArray[0]?.image}`}
            alt={"serviceImg"}
            width="100%"
            height={"200px"}
          />
        ) : (
          <img
            src={value.image ? value.image : ImagePlaceholder}
            alt={"serviceImg"}
            width="100%"
            height={"200px"}
          />
        )}
        {/* <div className={styles.imgBoxText}>
          <p>09/11/22 - 12/11/22</p>
          <p>Luness, 21:00h</p>
        </div> */}
      </section>
      <section
        className={styles.cardContentParent}
        style={{ height: value?.distance ? "100px" : "70px" }}
        onClick={details}
      >
        <h6>
          {(value?.imagesArray &&
            value?.imagesArray?.length > 0 &&
            value?.imagesArray[0]?.text) ||
            value?.title}
        </h6>
        <section className={styles.cardContent}>
          <div className={styles.child1}>
            <p>{value?.address}</p>
            {value?.distance && (
              <div className={styles.locationBOx}>
                <section className={`d-flex ${styles.imgChild1}`}>
                  <img
                    src={locationMarker}
                    alt="locationMarker"
                    width="30px"
                    height="20px"
                  />
                </section>
                <section className={`d-flex ${styles.imgChild2}`}>
                  <span>{value?.distance} KM </span>
                </section>
              </div>
            )}
          </div>
        </section>
      </section>
    </div>
  );
};

export default ServicesCard;
