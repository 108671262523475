import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import Audio from "../../Components/Audio";
import CustomHeader from "../../Components/CustomHeader";
import { navAction } from "../../Store/Actions/navAction";
import FlagDropDown from "../../Components/FlagDropDown";
import styles from "./style.module.css";
import Card from "./Cards";
import { pointDetailsCardData } from "../../logics/Data";
import PointSlider from "./PointSlider";
import Heading from "../../Components/Heading";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import API from "../../Config/API";
import { Spinner } from "react-bootstrap";
import { replaceHyphenWithSpace } from "../../logics/services";
const HeadPremiumDetails = () => {
  const [allData, setAllData] = useState([]);
  const [name, setName] = useState("Spanish");
  const [loading, setLoading] = useState(true);
  const { name: paramName, id } = useParams();

  const dispatch = useDispatch();
  let { state } = useLocation();

  const getpointInsidePremium = async () => {
    try {
      let response = await API.post("/get-points-inside-premium", {
        language: "Spanish",
        premiumId: id,
      });
      if (response?.data?.status === true) {
        setAllData(response?.data?.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setAllData([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    getpointInsidePremium();
    dispatch(navAction("pointOfinterest"));
  }, []);

  // console.log(state);
  // console.log("Data ==>", allData);

  return (
    <div>
      <CustomHeader
        label1={"Points Of Interest"}
        label2={replaceHyphenWithSpace(paramName)}
      />
      <div className="container py-5 f">
        <section className={`my-3 overflow-hidden ${styles.responsive}`}>
          {/* <Heading label="Sagrada Familia" /> */}
          <PointSlider allData={allData} />
        </section>
        {/* <section className={styles.dropDownAndAudio}>
          <div style={{ width:  }}>
            <Audio />
          </div>
          <div className="ms-3">
            <FlagDropDown setName={setName} />
          </div>
        </section> */}

        <section className={`my-5 row p-0 m-0 ${styles.inResponsive}`}>
          {/* {pointDetailsCardData.map((value) => {
            return <Card key={value.id} value={value} />;
          })} */}

          {loading ? (
            <section className="d-flex justify-content-center align-items-center">
              <Spinner style={{ color: "#35c89b", fontSize: 20 }} />
            </section>
          ) : allData && allData.length > 0 ? (
            allData.map((value, index, arr) => {
              return (
                <Card key={value.id} value={value} index={index} arr={arr} />
              );
            })
          ) : (
            <h1 className="text-center fw-bold">No Points</h1>
          )}
        </section>
      </div>
    </div>
  );
};

export default HeadPremiumDetails;
