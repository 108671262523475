import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import locationMarker from "../../Assets/locationBlackIcon.png";
import location from "../../Assets/locationssIcon.png";
import bannerImg from "../../Assets/banner.png";
import ImagePlaceholder from "../../Assets/ImagePlaceholder.png";
import { useNavigate } from "react-router-dom";
import starIcon from "../../Assets/starIcon.png";
import starIcon2 from "../../Assets/starIcon2.png";
import API from "../../Config/API";
import { formatTitle } from "../../logics/services";

const PointsOfInterstCart = ({ value, onclick }) => {
  const [currentUser, setCurrentUser] = useState("");
  const [isFollowed, setIsFollowed] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const user = localStorage.getItem("UserDetails");
    const parsedUser = JSON.parse(user);
    setCurrentUser(parsedUser);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      checkIsFollowed();
    }, 5000);
  }, [currentUser]);

  const details = () => {
    // navigate("/points-of-interest-details", { state: value });
    const title = value?.title?.split(" ");
    const formattedTitle = title?.join("");
    

    navigate(`/point/${formatTitle(value?.title)}/${value?.id}`, {
      state: value,
    });
  };

  // Follow Point
  const followPoint = async () => {
    if (currentUser) {
      try {
        const res = await API.post("/follow", {
          type: "point",
          userId: currentUser?.id,
          valueId: value?.id,
        });
        setIsFollowed(!isFollowed);
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  const checkIsFollowed = async () => {
    if (currentUser) {
      try {
        const res = await API.post("/isFollow", {
          type: "point",
          userId: currentUser?.id,
          valueId: value?.id,
        });
        if (res?.data?.data === "yes") {
          setIsFollowed(true);
        } else if (res?.data?.data === "yes") {
          setIsFollowed(false);
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  return (
    <div className={styles.cardBox}>
      {currentUser && (
        <section className={styles.fvrtIconBox} onClick={followPoint}>
          {isFollowed ? (
            <img
              src={starIcon2}
              width={"30px"}
              height={"30px"}
              alt="starIcon"
            />
          ) : (
            <img
              src={starIcon}
              width={"30px"}
              height={"30px"}
              alt="starIconColred"
            />
          )}
        </section>
      )}
      <section className={styles.cardImageBox} onClick={details}>
        {/* <img src={value.eventImg} alt="card1" width="100%" /> */}
        {value?.imagesArray?.length > 0 && value?.imagesArray[0]?.image ? (
          <img
            src={
              `https://veyveback.com/${value?.imagesArray[0]?.image}` ||
              bannerImg
            }
            alt={"logo"}
            width="100%"
            height={"200px"}
          />
        ) : (
          <img
            src={ImagePlaceholder}
            alt={"logo"}
            width="100%"
            height={"200px"}
          />
        )}
      </section>
      <section
        className={styles.cardContentParent}
        style={{
          height: value.distance ? "90px" : "70px",
        }}
        onClick={details}
      >
        <h6>{value?.title} </h6>
        <section className={styles.cardContent}>
          <div className={styles.child1}>
            <p>{value?.address}</p>
            {value?.distance && (
              <div className={styles.locationBOx}>
                <section className={`d-flex ${styles.imgChild1}`}>
                  <img
                    src={locationMarker}
                    alt="locationMarker"
                    width="30px"
                    height="20px"
                  />
                </section>
                <section className={`d-flex ${styles.imgChild2}`}>
                  <span>{value?.distance} KM </span>
                </section>
              </div>
            )}
          </div>
        </section>
      </section>
    </div>
  );
};

export default PointsOfInterstCart;
