import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import CustomHeader from "../../Components/CustomHeader";
import InnerContent from "./InnerContent";
import { useTranslation } from "react-i18next";
import useWindowDimensions from "../../logics/useWindowDimension";
import CustomModal from "../../Components/Modal";

const ServiceDetails = () => {
  const [title, setTitle] = useState("");
  const [show, setShow] = useState(false);
  const { name } = useParams();
  const { width } = useWindowDimensions();

  const handleClose = () => {
    setShow(false);
  };
  const { t } = useTranslation();

  useEffect(() => {
    if (!name && width < 550) {
      setShow(true);
    }
    console.log(show);
  }, [width]);

  return (
    <div>
      <CustomHeader label1={t("services")} label2={title} />
      <div className="container py-5 f">
        <CustomModal show={show} handleClose={handleClose} />
        <InnerContent setTitle={setTitle} />
        {/* <section className={styles.dropDownAndAudio}>
        <div style={{ width: 250 }}>
          <Audio />
        </div>
        <div className="ms-3">
          <FlagDropDown />
        </div>
      </section> */}
      </div>
    </div>
  );
};

export default ServiceDetails;
