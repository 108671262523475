import React from "react";
import styles from "./style.module.css";
import locationIcon from "../../../Assets/locationssIcon.png";
import { useNavigate, useParams } from "react-router-dom";
import pointCard1 from "../../../Assets/ImagePlaceholder.png";
import { formatTitle } from "../../../logics/services";
const Card = ({ value, arr, index }) => {
  const navigate = useNavigate();
  const { name } = useParams();
  ;

  const pointDetails = () => {
    const title = value?.title?.split(" ");
    const formattedTitle = title?.join("");
    navigate(`/head-point-premium/${name}/${formatTitle(value?.name)}/${value?.id}`);
    // navigate(`/premium-points-of-interest-details/`, {
    //   state: value,
    // });
  };

  return (
    <div
      className={styles.parent}
      style={{
        // border:"3px solid red",
        // borderRadius:"16px"
        cursor: "pointer",
      }}
      onClick={pointDetails}
    >
      <section
        className={styles.child1}
        style={{
          border: value.premiums && "4px solid #35C89B",
        }}
      >
        {/* <img src={value.eventImg} width="100%" alt="cardImg" /> */}
        <img
          src={
            value.imagesArray
              ? `https://veyveback.com/${value.imagesArray[0]?.image}`
              : value?.points && value.image
              ? `https://veyveback.com/${value.image}`
              : pointCard1
          }
          width="100%"
          height={"100%"}
          alt="cardImg"
        />
      </section>
      <section className={styles.child2}>
        <div>
          <h5>{value.name}</h5>
          <p>
            {value?.points?.length > 0 &&
              value?.points[0]?.description
                ?.slice(0, 100)
                .replace(/<\/?[^>]+(>|$)/g, "")}
          </p>
          {value?.points?.length > 0 && value?.points[0]?.distance && (
            <section
              style={{
                width: "max-content",
                gap: 5,
              }}
              className="border rounded  p-2 px-2 d-flex justify-content-between align-items-center"
            >
              <img
                alt="icon"
                className="me-2"
                width="25px"
                src={locationIcon}
              />
              <span style={{ fontSize: 12 }}>
                {value?.points[0]?.distance} KM Away
              </span>
            </section>
          )}
        </div>
        <div className="text-center" style={{ fontWeight: "bolder" }}>
          {index + 1}/{arr?.length}
        </div>
      </section>
    </div>
  );
};

export default Card;
