import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import newsBall from "../../Assets/newsBall.png";
import manIcon from "../../Assets/manIcon.png";
import ImagePlaceholder from "../../Assets/ImagePlaceholder.png";
import locationBlack from "../../Assets/locationBlackIcon.png";
import { useNavigate } from "react-router-dom";
import starIcon from "../../Assets/starIcon.png";
import starIcon2 from "../../Assets/starIcon2.png";
import API from "../../Config/API";
import MyNewsCard from "./MyNewsCard";
import { formatTitle } from "../../logics/services";

const NewsCard = ({ value }) => {
  const [currentUser, setCurrentUser] = useState("");
  const [isFollowed, setIsFollowed] = useState(false);
  const [filteredCategory, setFilteredCategory] = useState("");
  const [currentLanguage, setCurrentLanguage] = useState("");

  let date = value?.publicationDate;

  let parsedDate = new Date(date);

  // Day, month, aur year ko alag-alag variables mein store karo
  let day = parsedDate.getDate();
  let month = parsedDate.getMonth() + 1; // Month 0 se start hota hai, isliye 1 jodna zaroori hai
  let year = parsedDate.getFullYear();

  // Day aur month ko 2-digit format mein convert karo
  day = day < 10 ? "0" + day : day;
  month = month < 10 ? "0" + month : month;

  // Desired format mein date ko combine karo
  let formattedDate = day + "-" + month + "-" + year;

  const navigate = useNavigate();
  useEffect(() => {
    // Get Current user
    const user = localStorage.getItem("UserDetails");
    const parsedUser = JSON.parse(user);
    setCurrentUser(parsedUser);

    // Get Current Language
    const userLanguage = localStorage.getItem("userLanguage");
    setCurrentLanguage(userLanguage);
  }, []);

  useEffect(() => {
    getCategories();
  }, [currentLanguage]);

  useEffect(() => {
    setTimeout(() => {
      checkIsFollowed();
    }, 7000);
  }, [currentUser]);

  const details = () => {
    // navigate("/points-of-interest-details", { state: value });
    const title = value?.title?.split(" ");
    const formattedTitle = title?.join("");
    navigate(`/news-details/${formatTitle(value?.title)}/${value?.id}`, { state: value });
  };

  // Follow News
  const followNews = async () => {
    if (currentUser) {
      try {
        const res = await API.post("/follow", {
          type: "news",
          userId: currentUser?.id,
          valueId: value?.id,
        });
        setIsFollowed(!isFollowed);
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  const checkIsFollowed = async () => {
    if (currentUser) {
      try {
        const res = await API.post("/isFollow", {
          type: "news",
          userId: currentUser?.id,
          valueId: value?.id,
        });
        if (res?.data?.data === "yes") {
          setIsFollowed(true);
        } else if (res?.data?.data === "yes") {
          setIsFollowed(false);
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  // Get Categories
  const getCategories = async () => {
    try {
      const res = await API.post("/get-categories-lang", {
        type: "news",
        language: currentLanguage,
      });
      let categories = res?.data?.data;
      // Match categoryId with categories.id
      const matchedCategory = categories?.find(
        (category) => category.id == value?.categoryId
      );
      setFilteredCategory(matchedCategory);
      // console.log(matchedCategory);
    } catch (error) {
      console.log(error);
    }
  };

  const categoryName = filteredCategory?.name?.split(" ")[0];

  return (
    <>
      {/* {value?.imagesArray.length !== 0 ? (
        value?.imagesArray?.length > 0 && ( */}
      <div className={styles.cardBox}>
        {currentUser && (
          <section className={styles.fvrtIconBox} onClick={followNews}>
            {isFollowed ? (
              <img
                src={starIcon2}
                width={"30px"}
                height={"30px"}
                alt="starIcon"
              />
            ) : (
              <img
                src={starIcon}
                width={"30px"}
                height={"30px"}
                alt="starIconColred"
              />
            )}
          </section>
        )}

        <section className={styles.cardImageBox} onClick={details}>
          {value?.imagesArray?.length > 0 && value?.imagesArray[0] ? (
            <img
              src={`https://veyveback.com/${value?.imagesArray[0]?.image}`}
              alt="card1"
              width="100%"
              height={"200px"}
            />
          ) : (
            <img
              src={ImagePlaceholder}
              alt={"logo"}
              width="100%"
              height={"200px"}
            />
          )}
        </section>
        <section className={styles.cardContentParent} onClick={details}>
          <section className="d-flex justify-content-between align-items-center mb-3">
            <h6 className="fw-bold">{value?.title}</h6>
            <div className="d-flex">
              <img
                width="30"
                className="mr-3"
                src={
                  `https://veyveback.com/${filteredCategory?.image}` || newsBall
                }
                alt="icon"
              />
              <span>{categoryName}</span>
            </div>
          </section>
          <section className={styles.cardContent}>
            <div className={`d-flex align-items-center ${styles.child1}`}>
              <img
                src={
                  `https://veyveback.com/${value?.signatureImage}` || manIcon
                }
                width="30px"
                height="30px"
                alt="locationBlack"
              />
              <p className="ms-2 fw-bold">{value.eventDescription}</p>
            </div>
            <div className={styles.child2}>
              <span>{formattedDate}</span>
            </div>
          </section>
        </section>
      </div>
      {/* )
      ) : (
        <MyNewsCard value={value} />
      )} */}
    </>
  );
};

export default NewsCard;
